// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)

// //function that gets the location and returns it
// function getLocation() {
//   if(navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(showPosition);
//   } else {
//     console.log("Geo Location not supported by browser");
//   }

//   // $(document).ready(function() {
//   //   var lat = $(this).val();
//   //   $.ajax({
//   //       type: 'post',
//   //       url: 'properties/user_lat',
//   //       data: {lat: position.coords.latitude},
//   //   success: function(data) {
//   //     console.log(data);
//   //   }
//   //   });      
//   // })

//   // $(document).ready(function() {
//   //   var long = $(this).val();
//   //   $.ajax({
//   //       type: 'post',
//   //       url: 'properties/user_long',
//   //       data: {long: position.coords.longitude},
//   //   success: function(data) {
//   //     console.log(data);
//   //   }
//   //   });      
//   // })

// }

// // function getLocation(){
// //   if (navigator.geolocation) {
// //       navigator.geolocation.getCurrentPosition(function (position){
// //         $.ajax({
// //           type: 'GET',
// //           url: '/location',
// //           data: { lat: position.coords.latitude, lng: position.coords.longitude },
// //           contentType: 'application/json',
// //           dataType: 'json'
// //           }).done(function(data){
// //              console.log(data)
// //           });
// //       });
// //   }
// // }

// //function that retrieves the position
// function showPosition(position) {
//   var location = {
//     latitude: position.coords.latitude,
//     longitude: position.coords.longitude
//   }

//   $(document).ready(function() {
//     var lat = $(this).val();
//     $.ajax({
//         type: 'post',
//         url: '/user_lat',
//         data: {lat: position.coords.latitude},
//     success: function(data) {
//       console.log(data);
//     }
//     });      
//   })

//   $(document).ready(function() {
//     var long = $(this).val();
//     $.ajax({
//         type: 'post',
//         url: '/user_long',
//         data: {long: position.coords.longitude},
//     success: function(data) {
//       console.log(data);
//     }
//     });      
//   })

//   console.log(location)
// }

// // showPosition(position);

// //request for location
// getLocation();

// // Store Geolocaitont to Session.
// function getGeoLocation() {
//   navigator.geolocation.getCurrentPosition(setGeoCookie);
// }

// function setGeoCookie(position) {
//   var cookie_val = position.coords.latitude + "|" + position.coords.longitude;
//   document.cookie = "lat_lng=" + escape(cookie_val);
// }

// function XYZ(){
//   var variable = getLocation();
//   var xhr = new XMLHttpRequest();
//   xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
//   xhr.open('POST', 'properties/user_location');
//   xhr.onload = function() {
//     var rubyResponse = xhr.responseText;
//   };
//   xhr.send(encodeURI('js_var=' + variable));
// }

// $(document).ready(function() {
//   var newcoords = $(this).val();
//   $.ajax({
//       type: 'post',
//       url: 'properties/user_location',
//       data: {location: {lat:position.coords.latitude, long:position.coords.longitude}},
//   success: function(result){
//       $('#fieldID').val(result);
//   }
//   });      
// }

// $(document).ready(function() {
//   var lat = $(this).val();
//   $.ajax({
//       type: 'post',
//       url: 'properties/user_lat',
//       data: {lat:position.coords.latitude},
//   success: function(result){
//       $('#fieldID').val(result);
//     }
//   });      
// },

// $(document).ready(function() {
//   var long = $(this).val();
//   $.ajax({
//       type: 'post',
//       url: 'properties/user_long',
//       data: {long:position.coords.longitude},
//   success: function(result){
//       $('#fieldID').val(result);
//     }
//   });      
// }

// $(document).ready(() => $.ajax({
//   type: "POST",
//   url: "/properties/newcoords",
//   data: {lat:position.coords.latitude, long:position.coords.longitude},
//   success(data) {
//     alert(data.id);
//     return false;
//   },
//   error(data) {
//     return false;
//   }
// }));

// const Cookies = require('js-cookie'); //assign module to variable called "Cookies"
// Cookies.set('name', 'value');
// console.log(Cookies.get('name'));

// // For cookies_eu GDPR notice to work.
// cookiesEu.init()

// import Cookies from 'js.cookie';

// Cookies.set('name', 'value');
// console.log(Cookies.get('name'));

// const Cookies = require('js-cookie'); //assign module to variable called "Cookies"
// Cookies.set('name', 'value');
// console.log(Cookies.get('name'));

// // For cookies_eu GDPR notice to work.
// cookiesEu.init()
